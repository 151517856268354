import Link from 'next/link';

const CryptoFutureSection: React.FC = () => {
  return (
    <section className='px-[25px]'>
      <div className='lg:mt-[184px] mt-[148px] flex-wrap flex justify-around mx-auto max-w-[1112px] !border-b-primary border-b-[6px] gap-[38px] lg:gap-[112px] pb-8 lg:pb-[52px]'>
        <hgroup className='flex flex-col items-start gap-6 lg:gap-8'>
          <h2 className='max-w-[486px] text-white text-[28px]/9 lg:text-[52px]/[60px] font-semibold'>$Netty: The Future of Crypto Loans</h2>
          <ul className='flex items-center'>
            <li className='pr-[10px] lg:pr-[18px] text-white text-[11px] lg:text-lg/8 font-medium'>Ticker $NETTY</li>
            <li className='border-x border-x-primary px-[10px] lg:px-[18px] text-white text-[11px] lg:text-lg/8 font-medium'>Supply 500,000,000</li>
            <li className='pl-[10px] lg:pl-[18px] text-white text-[11px] lg:text-lg/8 font-medium'>Chain Ethereum</li>
          </ul>
          <p className='max-w-[499px] text-base lg:text-lg/8 font-medium !text-white-dark'>$NETTY tokens will power the first AI-loan Protocol, enabling on-chain loans for NFTs and Real-World Assets. Users earn rewards through borrowing, lending, and staking, while revenue supports burns, rewards, liquidity, and treasury to drive sustained innovation. </p>

          <Link href='https://docs.nettyworth.io/docs/tokenomics' target='_blank' className='flex justify-center items-center lg:w-[235px] h-14 rounded-[20px] w-full text-base lg:text-[19px]/5 !bg-primary text-white hover:opacity-75 active:scale-95'>
            Tokenomics
          </Link>
        </hgroup>
        <img src='/netty-worth-cycle-logo.png' alt='Netty Worth logo' className='w-[476px] object-contain' />
      </div>
    </section>
  );
};

export default CryptoFutureSection;
