import Feedback from '@/components/layouts-and-navs/landing/home/Feedback';

import CryptoFutureSection from './new/CryptoFutureSection';
import FaqSection from './new/FaqSection';
import FeaturesSection from './new/FeaturesSection';
import Header from './new/Header';
import HowWorksSection from './new/HowWorksSection';
import JoinFutureSection from './new/JoinFutureSection';
import VentureBackedArticle from './new/VentureBackedArticle';

const MainPage: React.FC = () => {
  return (
    <main className=''>
      <Header />
      <HowWorksSection />
      <FeaturesSection />
      <VentureBackedArticle />
      <CryptoFutureSection />
      <FaqSection />
      <Feedback />
      <JoinFutureSection />
    </main>
  );
};

export default MainPage;
